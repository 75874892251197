@if (departments$ | async; as departments) {
  <pxc-list-picker
    data-testid="department-picker"
    [items]="departments"
    [emptyMessage]="clientCode === 'common'
      ? 'Cet espace ne contient aucun groupe : votre séquence sera privée.'
      : 'Cet espace ne contient aucun groupe : vous ne pouvez pas créer de séquence ici.'"
    [selection]="departmentIds"
    (selectionChange)="departmentIdsChange.emit($event)"
    class="h-56"
  >
    <pxc-client-picker
      [disabled]="isClientChoiceDisabled"
      [clients]="clients$ | async"
      [selectedClient]="clientCode"
      (selectedClientChange)="clientCodeChange.emit($event)"
      data-testid="client-picker"
      class="w-full sm:max-w-80"
    ></pxc-client-picker>
  </pxc-list-picker>
}
<div class="flex justify-between flex-wrap gap-2 mt-2">
  <ng-content slot="[error]"></ng-content>
  <p class="text-right text-secondary ml-auto">
    {{ departmentIds.length | i18nPlural: pluralMapping }}
  </p>
</div>