<tui-scrollbar class="max-h-[65vh]">

  <pxc-filter-buttons></pxc-filter-buttons>

  @if (sadms$ | async; as sadms) {
    @if (isDataLoaded$ | async) {
      @if (sadms.length) {
        <div class="flex flex-col gap-12 py-4">
          @for (sadm of sadms; track sadm) {
            <div class="flex flex-col gap-2">
              <div class="flex justify-between gap-4 max-md:flex-col">
                <pxc-title
                  title
                  [title]="SadmTypeLabel[sadm.sadmType] | pluralize | titlecase"
                  titleSize="small"
                ></pxc-title>
                <button
                  tuiButton
                  *tuiLet="patient$ | async as patient"
                  appearance="secondary"
                  size="s"
                  [disabled]="patient.isArchived"
                  (click)="createEntity(sadm)"
                  [attr.data-testid]="'add-'+sadm.id+'-button'" 
                  iconStart="@tui.plus"
                >
                  Ajouter
                </button>
              </div>
              <pxc-sadm-entity-list
                [sadmsEntities]="sadm.entities"
                [sadmType]="sadm.sadmType"
                [attr.data-testid]="sadm.name"
              ></pxc-sadm-entity-list>
            </div>
          }
        </div>
      } @else {
        <ng-container [ngTemplateOutlet]="emptyState"></ng-container>
      }
    } @else {
      <div class="mx-auto my-4">
        <pxc-loader data-testid="loading">Récupération des questionnaires en cours ...</pxc-loader>
      </div>
    }
  } @else {
    <ng-container [ngTemplateOutlet]="emptyState"></ng-container>
  }

</tui-scrollbar>

<ng-template #emptyState>
  <div class="flex flex-col gap-2 items-center justify-center mx-auto my-3" data-testid="empty-state">
    <pxc-lottie-label
      animation="sequenceEmpty"
      message="Vous n'avez pas de questionnaires"
    ></pxc-lottie-label>
    <p class="max-w-prose mx-auto text-center">Contactez Pixacare pour activer un module de plaie.</p>
  </div>
</ng-template>
