@if (labels$ | async; as labels) {
  <div>
    <pxc-label-table
      [labels]="labels"
      [actions]="actions"
    >
      @if (department$ | async; as department) {
        <div slot="header" class="flex justify-between items-center p-3 border-b border-border-hover">
          <div class="flex items-center gap-3">
            <pxc-title title="Mots clés de {{department.name}}" titleSize="small"></pxc-title>
            <tui-badge appearance="info">
              {{labels.length}} mots clés
            </tui-badge>
          </div>
          <button
            slot="actions"
            tuiButton
            iconStart="@tui.plus"
            (click)="addLabels()"
          >
            Ajouter
          </button>
        </div>
      }
    </pxc-label-table>
  </div>
}