
<div class="flex flex-col items-center gap-2 my-4">

  <h6 class="text-center mb-2">{{ title }}</h6>

  @if (!sequences.length) {
    <p class="max-w-sm text-secondary text-center mb-2">{{ emptyState }}</p>
  } @else {
    <pxc-sequence-stacks-preview
      [sequences]="sequences"
      (mediaClicked)="mediaClicked.emit($event)"
      class="block w-full"
      [centered]="true"
    ></pxc-sequence-stacks-preview>
    <p class="text-center text-secondary text-sm">
      {{ sequences.length | i18nPlural : sequenceCountPluralMapping }}
    </p>
  }

  <button
    tuiButton
    size="m"
    appearance="outline"
    (click)="openSequencePicker()"
    [iconStart]="sequences.length ? '@tui.pencil' : '@tui.plus'"
    data-testid="open-picker"
  >
    {{ sequences.length ? 'Modifier la sélection' : 'Ajouter des séquences' }}
  </button>
</div>