import { TuiLet } from '@taiga-ui/cdk';
import { TuiTextfieldControllerModule, TuiInputModule } from '@taiga-ui/legacy';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy,
  Component, EventEmitter, Input, OnChanges, OnInit, Output,
  SimpleChanges } from '@angular/core';
import { ChatService } from '../chat.service';
import { MessageGroupService } from '../message-group.service';
import { ChannelListComponent } from '../channel-list/channel-list.component';
import { TuiScrollbar, TuiIcon, TuiButton } from '@taiga-ui/core';
import { ChannelPreviewComponent } from '../channel-preview/channel-preview.component';
import { Channel, SearchedUser } from '@pixacare/pxc-ts-core';
import { UserInputComponent } from 'src/app/shared/components/user-input/user-input.component';
import { BehaviorSubject, Observable, combineLatest, debounceTime, map, startWith, switchMap } from 'rxjs';
import { ChannelNamePipe } from '../pipes/channel-name.pipe';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { UserHttpService } from 'src/app/services/http/user.http.service';
import { ReceiverListComponent } from '../receiver-list/receiver-list.component';
import { StatusComponent } from '../../shared/status/status.component';
import { Status } from 'src/app/shared/models/enums/status.enum';

@Component({
  selector: 'pxc-receivers-picker',
  standalone: true,
  imports: [
    CommonModule,
    ChannelListComponent,
    TuiScrollbar,
    ChannelPreviewComponent,
    TuiLet,
    UserInputComponent,
    TuiInputModule,
    TuiTextfieldControllerModule,
    ReactiveFormsModule,
    SharedModule,
    ReceiverListComponent,
    TuiButton,
    StatusComponent,
    TuiIcon,
  ],
  providers: [
    ChatService,
    ChannelNamePipe,
    MessageGroupService,
  ],
  templateUrl: './receivers-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceiversPickerComponent implements OnInit, OnChanges {

  @Input() readonly selectedUsers: SearchedUser[] = [];
  @Input() readonly selectedChannels: Channel[] = [];
  @Input() readonly selectedMailAddresses: string[] = [];
  @Output() private readonly selectedUsersChange = new EventEmitter<SearchedUser[]>();
  @Output() private readonly selectedChannelsChange = new EventEmitter<Channel[]>();
  @Output() private readonly selectedMailAddressesChange = new EventEmitter<string[]>();

  channels$: Observable<Channel[]>;
  users$: Observable<SearchedUser[]>;

  readonly searchInput = new FormControl('', [Validators.email]);
  Status = Status;

  private readonly selectedUsers$: BehaviorSubject<SearchedUser[]> = new BehaviorSubject([]);
  private readonly selectedChannels$: BehaviorSubject<Channel[]> = new BehaviorSubject([]);
  private readonly USERS_LIMIT = 20;

  constructor(
    private readonly chatService: ChatService,
    private readonly userService: UserHttpService,
  ) { }

  ngOnInit(): void {
    this.channels$ = combineLatest([
      this.searchInput.valueChanges.pipe(
        startWith(''),
        debounceTime(300),
        switchMap((search) => this.chatService.searchChannels$(search)),
      ),
      this.selectedChannels$,
    ]).pipe(
      map(([channels, selectedChannels]) => channels.filter((channel) =>
        !selectedChannels.some((selectedChannel) => selectedChannel?.id === channel.id)),
      ),
    );

    this.users$ = combineLatest([
      this.searchInput.valueChanges.pipe(
        startWith(''),
        debounceTime(300),
        switchMap((search) => this.userService.searchUsers(search)),
        map((users) => users.splice(0, this.USERS_LIMIT)),
      ),
      this.selectedUsers$,
    ]).pipe(
      map(([users, selectedUsers]) => users.filter((user) =>
        !selectedUsers.some((selectedUser) => selectedUser?.id === user.id))),
    );
  }

  ngOnChanges({ selectedChannels, selectedUsers }: SimpleChanges): void {
    if (selectedChannels) {
      this.selectedChannels$.next(selectedChannels.currentValue);
    }

    if (selectedUsers) {
      this.selectedUsers$.next(selectedUsers.currentValue);
    }

    if (selectedChannels || selectedUsers) {
      this.searchInput.reset();
    }
  }

  submit(): void {
    if (!this.searchInput.valid) {return;}

    const email = this.searchInput.value;

    if (!email) {return;}

    if (this.selectedMailAddresses.some((selectedMailAddress) => selectedMailAddress === email)) {
      return;
    }

    this.selectedMailAddressesChange.emit([...this.selectedMailAddresses, email]);
    this.searchInput.reset();
  }

  selectChannel(channel: Channel): void {
    if (this.selectedChannels.some((selectedChannel) => selectedChannel?.id === channel.id)) {
      return;
    }
    this.selectedChannelsChange.emit([...this.selectedChannels, channel]);
  }

  selectUser(user: SearchedUser): void {
    if (this.selectedUsers.some((selectedUser) => selectedUser?.id === user.id)) {
      return;
    }
    this.selectedUsersChange.emit([...this.selectedUsers, user]);
  }

  removeChannel(channel: Channel): void {
    this.selectedChannelsChange.emit(this.selectedChannels.filter((c) => c !== channel));
  }

  removeUser(user: SearchedUser): void {
    this.selectedUsersChange.emit(this.selectedUsers.filter((u) => u !== user));
  }

  removeMailAddress(mailAddress: string): void {
    this.selectedMailAddressesChange.emit(this.selectedMailAddresses.filter((m) => m !== mailAddress));
  }

}
