<div class="flex gap-1 items-center" *tuiLet="'text-secondary text-sm' as subtextStyle">
  <div class="flex flex-row items-center gap-3" data-testid="patient-data">
    <div class="flex flex-col overflow-hidden text-left">
      <div [ngClass]="customClass || 'text-primary'" data-testid="title">
        @switch (size) {
          @case (Size.MEDIUM) {
            <h6 class="flex gap-2 leading-snug">
              <ng-container *ngTemplateOutlet="patientTitle"></ng-container>
            </h6>
          }
          @case (Size.LARGE) {
            <h4 class="flex gap-3 leading-snug">
              <ng-container *ngTemplateOutlet="patientTitle"></ng-container>
            </h4>
          }
          @default {
            <p class="font-medium leading-snug">
              <ng-container *ngTemplateOutlet="patientTitle"></ng-container>
            </p>
          }
        }
      </div>
      @if (patient) {
        @if (patient.birthName) {
          <p [ngClass]="customClass || subtextStyle" data-testid="birth-name">
            Né(e) <span [class.text-secondary]="!customClass">{{ patient.birthName }}</span> le <ng-container *ngTemplateOutlet="birthDate"></ng-container>
          </p>
        } @else {
          <p [ngClass]="customClass || subtextStyle" data-testid="birth-date">
            Né(e) le <ng-container *ngTemplateOutlet="birthDate"></ng-container>
          </p>
        }
        @if (patient.businessIdentifier) {
          <p [ngClass]="customClass || subtextStyle" data-testid="nip">
            NIP : <span [class.text-secondary]="!customClass">{{ patient.businessIdentifier }}</span>
          </p>
        }
        <ng-content select="[additional-data]"></ng-content>
      }
    </div>
  </div>

  <ng-template #birthDate>
    <span [class.text-secondary]="!customClass">{{ patient.birthDate | date: 'shortDate' }}</span>
  </ng-template>

  <ng-template #patientTitle>
    {{ patient | patientName }}
    <ng-content></ng-content>
  </ng-template>

</div>