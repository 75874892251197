@if (selectedChannels.length > 0 || selectedUsers.length > 0 || selectedMailAddresses.length > 0) {
  <pxc-receiver-list
    [channels]="selectedChannels"
    [users]="selectedUsers"
    [mailAddresses]="selectedMailAddresses"
    (removeChannel)="removeChannel($event)"
    (removeUser)="removeUser($event)"
    (removeMailAddress)="removeMailAddress($event)"
  ></pxc-receiver-list>
}

<tui-input class="mt-3" [formControl]="searchInput" (keydown.enter)="submit()" tuiTextfieldIconLeft="@tui.search">
  Cherchez par nom, conversation ou ajoutez un email
</tui-input>

<div
  class="w-full flex flex-col min-h-52 max-h-[50vh] my-3"
  *tuiLet="({ channels: (channels$ | async), users: (users$ | async) } ) as data"
>
  @if (data.channels || data.users) {
    <tui-scrollbar class="flex-1 max-h-[53vh]">
      @if (data.channels?.length > 0 || data.users?.length > 0) {
        <div class="flex flex-col gap-3 relative">
          @if (data.channels?.length > 0) {
            <pxc-title
              class="sticky top-0 bg-base lg:bg-base-alt z-10"
              title="Conversations"
              titleSize="small"
            ></pxc-title>
            <div class="flex flex-col">
              @for (channel of data.channels; track channel) {
                <pxc-channel-preview
                  class="cursor-pointer transition hover:bg-neutral-1 px-4 py-2 z-0"
                  [channel]="channel"
                  [showDetails]="false"
                  (click)="selectChannel(channel)"
                ></pxc-channel-preview>
              }
            </div>
          }
          @if (data.users?.length > 0) {
            <pxc-title
              class="sticky top-0 bg-base lg:bg-base-alt z-10"
              title="Utilisateurs"
              titleSize="small"
            ></pxc-title>
            <div class="flex flex-col">
              @for (user of data.users; track user) {
                <pxc-user-card
                  class="cursor-pointer transition hover:bg-neutral-1 px-4 py-2"
                  [user]="user"
                  (click)="selectUser(user)"
                ></pxc-user-card>
              }
            </div>
          }
        </div>
      } @else {
        <pxc-status
          [state]="Status.BASIC"
          [icon]="searchInput.value.includes('@') ? '@tui.at-sign' : searchInput.value ? '@tui.search' : '@tui.meh'"
          [showTitle]="false"
          [description]="searchInput.value.includes('@') 
            ? 'Entrez un email et appuyez sur Entrée pour ajouter un utilisateur.' 
            : searchInput.value 
              ? 'Aucune conversation ou utilisateur ne correspond à votre recherche.' 
              : 'Vous n\'avez pas encore de conversations ! Cherchez un utilisateur ou entrez un email pour démarrer.'
          "
        ></pxc-status>
      }
    </tui-scrollbar>
  } @else {
    <pxc-loader size="tiny"></pxc-loader>
  }
</div>
