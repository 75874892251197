import { TuiLet } from '@taiga-ui/cdk';
import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, OnInit, Optional, Inject, Input } from '@angular/core';
import { AnimateFadeDirective } from '@modules/shared/directives/animate-fade.directive';
import { Store } from '@ngrx/store';
import { ClientDataConsumption } from '@pixacare/pxc-ts-core';
import { ContactUsService } from '@services/contact-us.service';
import { DialogService } from '@services/dialog.service';
import { StorageService } from '@services/storage.service';
import { DataConsumptionState } from '@shared/models/paywall/data-consumption-state';
import { dataConsumptionStateConfig } from '@shared/models/paywall/data-consumption-state.config';
import { FormatGoDataUnitPipe } from '@shared/pipes/format-go-data-unit.pipe';
import { selectClientDataConsumptionInGb } from '@shared/store/clients/clients.selectors';
import { selectClientCode } from '@shared/store/router/router.selectors';
import { TuiDialogContext, TuiIcon, TuiButton, TuiHint } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { Observable, switchMap, map } from 'rxjs';

@Component({
  selector: 'pxc-data-consumption',
  templateUrl: './data-consumption.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiLet,
    TuiIcon,
    FormatGoDataUnitPipe,
    AnimateFadeDirective,
    TuiHint,
    TuiButton,
  ],
})
export class DataConsumptionComponent implements OnInit {

  @Input() collapsed = this.context?.data.collapsed || false;
  @Input() detailedMode = this.context?.data.detailedMode || false;

  dataConsumptionState$: Observable<DataConsumptionState>;

  activeClientDataConsumption$: Observable<ClientDataConsumption> = this.store.select(selectClientCode).pipe(
    switchMap((clientCode) => this.store.select(selectClientDataConsumptionInGb(clientCode))),
  );

  constructor(
    private readonly store: Store,
    private readonly storageService: StorageService,
    private readonly dialogService: DialogService,
    @Optional() @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, {
      collapsed?: boolean;
      detailedMode?: boolean;
    }>,
    private readonly contactUsService: ContactUsService,
  ) { }

  ngOnInit() {
    this.dataConsumptionState$ = this.storageService.getActiveClientStorageSpace()
      .pipe(
        map((storageSpace) => dataConsumptionStateConfig[storageSpace]),
      );
  }

  manageStorage(): void {
    this.dialogService.openComponent(DataConsumptionComponent, {
      label: 'Gérer votre stockage',
      data: { detailedMode: true },
    }).subscribe();
  }

  contact(): void {
    this.contactUsService.openPixacareSupportForm();
  }

}
