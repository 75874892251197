<div
  class="w-full flex flex-col border-r border-border-normal h-full gap-2"
  *tuiLet="(channels$ | async) as channels"
>
  <div class="flex gap-2 items-center justify-between px-4 pt-3">
    <h5 class="leading-loose">Messagerie</h5>
    <button
      type="button"
      tuiIconButton
      appearance="secondary"
      iconStart="@tui.plus"
      size="s"
      aria-label="Créer une conversation"
      (click)="openCreateChannelDialog()"
    ></button>
  </div>
  <pxc-filter-bar
    class="px-4 py-1"
    searchPlaceholder="Rechercher une conversation ..."
    size="m"
  ></pxc-filter-bar>
  @if (channels?.length > 0) {
    <tui-scrollbar class="flex-1">
      <div class="flex flex-col">
        @for (channel of channels; track channel) {
          <pxc-channel-preview
            class="cursor-pointer transition hover:bg-neutral-1 px-4 py-2"
            [channel]="channel"
            [routerLink]="['./', channel.id]"
            [routerLinkActive]="['bg-neutral-1']"
            queryParamsHandling="merge"
          ></pxc-channel-preview>
        }
      </div>
    </tui-scrollbar>
  } @else {
    <pxc-status
      *tuiLet="(search$ | async) as search"
      [state]="Status.BASIC"
      [icon]="!search ? '@tui.message-circle' : '@tui.meh'"
      [showTitle]="false"
      [description]="search ? 'Aucune conversation ne correspond à votre recherche.' : 'Vous n\'avez pas encore de conversations !'"
    ></pxc-status>
  }
</div>
