<div class="flex flex-col gap-4">

  <pxc-title
    title="Ajouter des collaborateurs"
    titleSize="tiny"
    subtitle="Choisissez qui sera ajouté à la conversation du télésuivi afin d'être notifié des mises à jour."
    data-testid="title"
  ></pxc-title>

  <pxc-user-input
    [allowEmails]="true"
    [disabled]="disabled"
    [ngModel]="userInputValue"
    (ngModelChange)="onSelectChange($event)"
    [placeholder]="placeholder"
    [excludedIds]="excludedIds"
    [allowEmails]="false"
    data-testid="input"
    ngDefaultControl
    class="min-h-20"
  ></pxc-user-input>

  <tui-scrollbar class="max-h-96 min-h-24">
    <p class="font-medium text-secondary text-sm pb-2 top-0 sticky w-full bg-white md:bg-base-alt z-10">Collaborateurs</p>
    <div class="flex flex-col gap-3" tuiFade="vertical" tuiScrollable>
      @for (item of selectedUsers; track item; let idx = $index) {
        <div class="flex gap-2 items-center">
          <pxc-user-card
            [user]="item.user"
            [mailAddress]="item.email"
            class="flex-1"
            [attr.data-testid]="'user-card-'+item.user?.id"
          ></pxc-user-card>
          @if (item.user?.id !== currentUserId) {
            <button
              tuiButtonClose
              tuiIconButton
              (click)="removeUser(idx)"
              [attr.data-testid]="'remove-user-'+item.user?.id"
              type="button"
              class="mr-4"
            ></button>
          }
        </div>
      }
    </div>
    @if (suggestedUsers$ | async; as suggestedUsers) {
      @if (shouldShowSuggestions(suggestedUsers)) {
        <div class="h-12"></div>
        <div class="flex gap-2 justify-between flex-wrap text-sm pb-2 top-0 sticky flex-1 bg-white md:bg-slate-50 z-10">
          <p class="font-medium text-slate-600 text-sm">Suggestions</p>
          <button
            tuiLink
            (click)="addAllSuggestions(suggestedUsers)"
            type="button"
            data-testid="add-all-suggestions"
            appearance="flat"
          >
            Ajouter tout
          </button>
        </div>
        <div class="flex flex-col gap-3" tuiFade="vertical" tuiScrollable>
          @for (user of suggestedUsers; track user.id+user.medicalSpecialtyLabel; let idx = $index) {
            @if (shouldShowUser(user.id)) {
              <div class="flex gap-2 items-center">
                <pxc-user-card
                  [user]="user"
                  class="flex-1"
                  [attr.data-testid]="'suggested-user-'+user.id"
                ></pxc-user-card>
                <button
                  tuiButton
                  (click)="addFromSuggestion(user)"
                  appearance="outline"
                  [attr.data-testid]="'add-suggestion-'+user.id"
                  type="button"
                >
                  Ajouter
                </button>
              </div>
            }
          }
        </div>
      }
    }
  </tui-scrollbar>
</div>