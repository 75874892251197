/* eslint-disable @typescript-eslint/member-ordering */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@modules/shared/shared.module';
import { Store } from '@ngrx/store';
import { ClientUser, SearchedUser } from '@pixacare/pxc-ts-core';
import { AuthenticationService } from '@services/authentication.service';
import { UserCardComponent } from '@shared/components/user-card/user-card.component';
import { UserInputComponent } from '@shared/components/user-input/user-input.component';
import { UserInputSelection } from '@shared/models/helpers/user-input-selection';
import { selectClientCode } from '@shared/store/router/router.selectors';
import { selectEnabledDepartmentUsers } from '@shared/store/users/users.selectors';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiScrollbar, TuiButton, TuiOption, TuiLink, TuiIcon, TuiScrollable } from '@taiga-ui/core';
import { TuiButtonClose, TuiFade } from '@taiga-ui/kit';
import { combineLatest, map, Observable, shareReplay, switchMap } from 'rxjs';

type OnChangeFn = (value: UserInputSelection[]) => void;

@Component({
  selector: 'pxc-telemonitoring-collaborators',
  standalone: true,
  imports: [
    CommonModule,
    UserInputComponent,
    UserCardComponent,
    TuiScrollbar,
    FormsModule,
    ReactiveFormsModule,
    TuiButton,
    SharedModule,
    TuiLet,
    TuiButtonClose,
    TuiOption,
    TuiLink,
    TuiIcon,
    TuiFade,
    TuiScrollable,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TelemonitoringCollaboratorsComponent,
    },
  ],
  templateUrl: './telemonitoring-collaborators.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelemonitoringCollaboratorsComponent implements OnChanges, ControlValueAccessor {

  @Input() departmentIds: number[] = [];
  @Input() placeholder = 'Ajoutez un utilisateur ou une adresse email';

  private readonly store = inject(Store);
  readonly currentUserId = inject(AuthenticationService).currentUser.id;
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  suggestedUsers$: Observable<SearchedUser[]>;
  selectedUsers: UserInputSelection[] = [];
  disabled = false;
  touched = false;
  excludedIds = [this.currentUserId];
  userInputValue = [];

  onChange: OnChangeFn = () => {};
  onTouched: () => void = () => {};

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.departmentIds) {
      return;
    }

    this.suggestedUsers$ = this.store.select(selectClientCode).pipe(
      switchMap((cc: string) => combineLatest(
        this.departmentIds.map((departmentId) =>
          this.store.select(selectEnabledDepartmentUsers(cc, departmentId))),
      ).pipe(
        map((users) => Array.from(new Set(users.flat()))),
        map((users) => users.map(this.clientUserToSearchedUser)),
        shareReplay(1),
      )),
    );
  }

  clientUserToSearchedUser(user: ClientUser): SearchedUser {
    if (!user) {
      return null;
    }

    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      liveId: user.liveId,
      clientNames: [],
      medicalSpecialtyLabel: user.mailAddress,
    } as SearchedUser;
  }

  writeValue(obj: UserInputSelection[]): void {
    this.selectedUsers = obj;
    this.updateExcludedIds();
  }

  registerOnChange(fn: OnChangeFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateExcludedIds(): void {
    this.excludedIds = [
      this.currentUserId,
      ...this.selectedUsers
        .filter(({ user }) => !!user)
        .map(({ user }) => user.id),
    ];
  }

  onSelectChange(value: UserInputSelection[]): void {
    this.touched = true;
    this.selectedUsers.push(...value);
    this.userInputValue = [];
    this.updateExcludedIds();
    this.onTouched();
    this.onChange(this.selectedUsers);
    this.changeDetectorRef.markForCheck();
  }

  removeUser(idx: number): void {
    this.touched = true;
    this.selectedUsers.splice(idx, 1);
    this.updateExcludedIds();
    this.onTouched();
    this.onChange(this.selectedUsers);
  }

  addFromSuggestion(user: SearchedUser): void {
    this.onSelectChange([{ user }]);
  }

  addAllSuggestions(users: SearchedUser[]): void {
    const usersToAdd = users
      .filter((user) => !this.excludedIds.includes(user.id) && user.id !== this.currentUserId)
      .map((user) => ({ user }));
    this.onSelectChange(usersToAdd);

  }

  shouldShowUser(userId: number): boolean {
    return userId !== this.currentUserId && !this.excludedIds.includes(userId);
  }

  shouldShowSuggestions(users: SearchedUser[]): boolean {
    return users.some((user) => this.shouldShowUser(user.id));
  }

}
