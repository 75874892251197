<h2>Changement de mot de passe</h2>

<form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()" data-testid="change-password-form" class="flex flex-col gap-4 mt-6">
  <div>
    <tui-input-password
      formControlName="inputOldPassword"
      tuiTextfieldIconLeft="@tui.lock-open"
      [disabled]="(actionOnGoing$ | async) ? '' : null"
    >
      Ancien mot de passe
    </tui-input-password>
    <app-input-validation-error-display
      [control]="inputOldPassword"
    ></app-input-validation-error-display>
  </div>

  <div>
    <tui-input-password
      formControlName="inputNewPassword"
      tuiTextfieldIconLeft="@tui.lock"
      [disabled]="(actionOnGoing$ | async) ? '' : null"
    >
      Nouveau mot de passe
    </tui-input-password>
    <app-input-validation-error-display
      [control]="inputNewPassword"
    ></app-input-validation-error-display>
  </div>

  <div>
    <tui-input-password
      formControlName="inputNewPasswordConfirm"
      tuiTextfieldIconLeft="@tui.lock"
      [disabled]="(actionOnGoing$ | async) ? '' : null"
    >
      Confirmation du nouveau mot de passe
    </tui-input-password>
    <app-input-validation-error-display
      [control]="inputNewPasswordConfirm"
    ></app-input-validation-error-display>
  </div>

  <div class="flex flex-col gap-2 mt-2">
    <button
      tuiButton
      class="w-full"
      type="submit"
      appearance="primary"
      [loading]="actionOnGoing$ | async"
    >
      Changer mon mot de passe
    </button>

    <button
      tuiButton
      appearance="outline"
      class="w-full"
      routerLink="/"
      [disabled]="actionOnGoing$ | async"
    >
      Retour à l'accueil
    </button>

  </div>


</form>

