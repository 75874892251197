<div
  class="grid grid-cols-5 gap-4"
  *tuiLet="(value?.length || rejectedFiles?.length || medias?.length) as hasFiles"
>

  @for (media of medias; track media; let idx = $index) {
    <pxc-image-overlay
      [showDelete]="showDelete && !disabled"
      (delete)="removeMedia(media)"
      [attr.data-testid]="'media-'+media.id"
    >
      <pxc-sequence-media
        [src]="media.thumbnailUri"
        [sequenceId]="media.sequenceId"
        [clientCode]="media.clientCode"
        [mediaId]="media.id"
        (click)="mediaClickedIdx.emit(idx)"
        class="cursor-zoom-in"
        [attr.data-testid]="'seq-media-'+media.id"
      ></pxc-sequence-media>
      <img [src]="media.thumbnailUri" class="w-full aspect-square rounded-xl">
    </pxc-image-overlay>
  }

  @for (file of value; track file) {
    <pxc-file-image-preview
      [file]="file"
      class="w-full"
      [showDelete]="showDelete && !disabled"
      (delete)="removeFile(file)"
      [attr.data-testid]="'file-'+file.name"
    ></pxc-file-image-preview>
  }

  @for (file of rejectedFiles; track file) {
    <pxc-image-overlay
      (delete)="removeRejected(file)"
      [showDelete]="showDelete && !disabled"
    >
      <div
        class="w-full aspect-square rounded-xl bg-status-negative-pale flex flex-col justify-center items-center p-2 gap-2 select-none"
      >
        <tui-icon icon="@tui.image-off" class="text-status-negative"></tui-icon>
        <p class="text-secondary text-sm text-center line-clamp-2 max-w-full break-all">
          {{ file.name }}
        </p>
        <p class="text-xs text-secondary text-center">
          Ce fichier n'est pas pris en charge
        </p>
      </div>
    </pxc-image-overlay>
  }

  <label 
    tuiInputFiles 
    class="aspect-square"
    [ngClass]="{
        'col-span-5': !hasFiles,
        'aspect-square w-full': hasFiles
    }"
  >
    <input
      tuiInputFiles
      [accept]="accept"
      [multiple]="true"
      (ngModelChange)="onFileChange($event)"
      [ngModel]="value"
      [disabled]="disabled"
      (reject)="onReject($event)"
      data-testid="image-input"
      ngDefaultControl
    />

    <ng-template let-dragged>

      <div class="flex flex-col gap-3 items-center py-4 text-sm">
        @if (dragged) {
          <tui-icon 
            class="text-pixablue-1"
            icon="@tui.smile"
          ></tui-icon>
          Déposez l'image ici !
        } @else {
          <tui-icon 
            class="text-secondary"
            icon="@tui.plus"
          ></tui-icon>
          <span class="text-secondary">{{label}}</span>
        }
      </div>

    </ng-template>
   </label>

</div>