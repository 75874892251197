<div class="flex flex-col gap-3 h-full w-full">
  @if (reports?.length ) {
    <pxc-sadm-entity-thumbnails-carousel
      [reports]="reports"
      [sadmEntityId]="sadmEntityId"
      [clientCode]="clientCode"
      [selectedIndex]="currentIndex"
      (selectedIndexChange)="setCurrentIndex($event)"
      data-testid="thumbnails-preview"
      [itemsCount]="mathMin(fullScreen ? 4 : 3, reports.length)"
      class="mx-auto max-w-full px-4"
    ></pxc-sadm-entity-thumbnails-carousel>
    <tui-scrollbar class="flex-1" [ngClass]="{'2xl:max-h-[37vh]': !fullScreen}">
      <pxc-form-display
        [sections]="reports[currentIndex].sections"
        data-testid="form-answers"
      ></pxc-form-display>
    </tui-scrollbar>
    <div class="flex gap-2 justify-end px-4">
      <ng-content></ng-content>
      @if (!fullScreen) {
        <button
          tuiButton
          appearance="secondary"
          iconEnd="@tui.external-link"
          (click)="openFullscreen()"
          size="s"
          data-testid="fullscreen-form"
        >
          Ouvrir
        </button>
      }
    </div>
  } @else {
    <div data-testid="no-summary" class="px-4">
      <h6 class="max-w-prose text-center mx-auto">Il n'y a aucun rapport d'analyse disponible.</h6>
    </div>
  }
</div>
