<pxc-back-navigation-button
  class="block max-lg:pt-2 pb-4"
  label="Retour à la liste"
  defaultUrl="/dashboard/patients"
  data-testid="back-button"
></pxc-back-navigation-button>

@if (isPatientStateLoaded$ | async) {
  @if (patient$ | async; as selectedPatient) {
    <div class="flex max-md:flex-col md:items-center md:justify-between gap-5 mb-2 animate-in fade-in">
      <div class="flex items-center gap-5" data-testid="patient-data">
        <pxc-patient-avatar
          [patient]="selectedPatient"
          [size]="Size.LARGE"
        ></pxc-patient-avatar>
        <pxc-patient-data
          [patient]="selectedPatient"
          data-testid="patient-details"
          size="large"
        >
          <pxc-patient-link-quick-action  *showIfClientIsGamEnabled="selectedPatient"></pxc-patient-link-quick-action>
          @if (selectedPatient.isArchived) {
            <div>
              <pxc-archived-badge></pxc-archived-badge>
            </div>
          }
        </pxc-patient-data>
      </div>
      <pxc-action-buttons
        [entity]="selectedPatient"
        [actions]="patientActions"
        [actionButtons]="actionButtonsGroups"
        data-testid="actions"
        optionButtonAppearance="outline"
      ></pxc-action-buttons>
    </div>
    @if (selectedPatient.isArchived) {
      <pxc-archived-notification
        title="Ce patient est archivé"
        description="Vous ne pouvez plus modifier ou ajouter de données à ce patient.">
      </pxc-archived-notification>
    }
    <nav tuiTabs class="mb-2 animate-in fade-in" data-testid="tabs">
      @for (tab of tabs; track tab) {
        <button
          tuiTab
          [routerLink]="tab.link"
          [routerLinkActive]="['active']"
          [queryParams]="tab.queryParams"
          queryParamsHandling="merge"
          [attr.data-testid]="tab.link"
        >
          <tui-icon [icon]="tab.icon" class="mr-1.5"></tui-icon>
          {{ tab.title }}
        </button>
      }
    </nav>
    <div class="animate-in fade-in">
      <router-outlet></router-outlet>
    </div>
  } @else {
    <div class="flex flex-col items-center justify-center gap-4">
      <pxc-lottie-label animation="patientEmpty" message="Oups !"></pxc-lottie-label>
      <p>Désolé, nous n'avons pas trouvé le patient que vous cherchiez ...</p>
      <a tuiButton routerLink="/dashboard/patients" queryParamsHandling="merge" >Retour à la liste des patients</a>
    </div>
  }
} @else {
  <pxc-loader data-testid="loading">Chargement du patient ...</pxc-loader>
}
