<div class="flex justify-between items-center p-3 border-b border-border-normal">
    <div class="flex flex-col max-md:flex-col gap-3">
        <div class="flex items-center gap-3 py-1">
            <p class="font-medium">{{title}}</p>
            <tui-badge appearance="info">{{ counterLabel }}</tui-badge>
        </div>
        <pxc-user-state-count
            [userCountByAdminState]="userCountByAdminState"
        ></pxc-user-state-count>
    </div>
    <pxc-action-buttons
        [actions]="actions"
        [actionButtons]="actionButtonsGroups"
    ></pxc-action-buttons>
</div>