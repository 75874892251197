import { TuiLet } from '@taiga-ui/cdk';
import {
  TuiIslandDirective,
  TuiTextfieldControllerModule,
  TuiTextareaModule,
  TuiInputDateTimeModule,
} from '@taiga-ui/legacy';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SequenceListComponent } from './sequence-list/sequence-list.component';
import { SequenceCreateComponent } from './sequence-create/sequence-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { PatientModule } from '../patient/patient.module';
import { RouterModule } from '@angular/router';
import { SequenceMoveComponent } from './sequence-move/sequence-move.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SequenceEditComponent } from './sequence-edit/sequence-edit.component';
import { SequencePreviewDrawerComponent } from './sequence-preview-drawer/sequence-preview-drawer.component';
import {
  TuiCarousel,
  TuiElasticContainer,
  TuiItemsWithMore,
  TuiStepper,
  TuiBadge,
  TuiBadgedContent,
  TuiBlock,
  TuiCheckbox,
  TuiAvatar,
  TuiButtonLoading,
  TuiChip,
} from '@taiga-ui/kit';
import { LayoutModule } from '@angular/cdk/layout';
import { SequenceMediaComponent } from './sequence-media/sequence-media.component';
import {
  TuiNotification,
  TuiLoader,
  TuiScrollbar,
  TuiLabel,
  TuiDropdown,
  TuiIcon,
  TuiLink,
  TuiButton,
  TuiHint,
} from '@taiga-ui/core';
import { SadmModule } from '../sadm/sadm.module';
import { SequenceStacksPreviewComponent } from './sequence-stacks-preview/sequence-stacks-preview.component';
import { ClientPickerComponent } from '../client/client-picker/client-picker.component';
import { PaywallAlertComponent } from '../paywall/paywall-alert/paywall-alert.component';
import { UserInputComponent } from 'src/app/shared/components/user-input/user-input.component';
import { SequencePreviewComponent } from './sequence-preview/sequence-preview.component';
import { SpinnerComponent } from '../shared/spinner/spinner.component';
import { ImageInputComponent } from '../shared/image-input/image-input.component';
import { ImageBrokenComponent } from '../shared/image-broken/image-broken.component';
import { ImageLoadingComponent } from '../shared/image-loading/image-loading.component';
import { FilterBarComponent } from '../filters/filter-bar/filter-bar.component';
import { FilterTabsComponent } from '../filters/filter-tabs/filter-tabs.component';
import { LabelComponent } from '../label/label/label.component';
import { SelectLabelComponent } from '../label/select-label/select-label.component';
import { DepartmentPickerComponent } from '../department/department-picker/department-picker.component';
import { DrawerComponent } from '@modules/drawer/drawer/drawer.component';
import {
  TelemonitoringRedirectButtonComponent,
} from '@modules/telemonitoring/telemonitoring-redirect-button/telemonitoring-redirect-button.component';

@NgModule({
  declarations: [
    SequenceListComponent,
    SequenceCreateComponent,
    SequenceMoveComponent,
    SequenceEditComponent,
    SequencePreviewDrawerComponent,
    SequenceStacksPreviewComponent,
  ],
  imports: [
    CommonModule,
    ClientPickerComponent,
    PatientModule,
    PaywallAlertComponent,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    RouterModule,
    DragDropModule,
    DrawerComponent,
    LayoutModule,
    FilterBarComponent,
    FilterTabsComponent,
    ...TuiCarousel,
    TuiIslandDirective,
    TuiButton,
    ...TuiDropdown,
    TuiIcon,
    ...TuiStepper,
    TuiInputDateTimeModule,
    TuiTextfieldControllerModule,
    TuiTextareaModule,
    TuiElasticContainer,
    TuiLoader,
    TuiLabel,
    TuiBlock, TuiCheckbox,
    ...TuiHint,
    TuiAvatar,
    TuiScrollbar,
    SadmModule,
    ...TuiItemsWithMore,
    TuiLet,
    ...TuiBadgedContent,
    TuiNotification,
    SequencePreviewComponent,
    SequenceMediaComponent,
    UserInputComponent,
    SpinnerComponent,
    ImageInputComponent,
    ImageLoadingComponent,
    ImageBrokenComponent,
    LabelComponent,
    SelectLabelComponent,
    DepartmentPickerComponent,
    TuiLink,
    TuiBadge,
    TuiButtonLoading,
    TuiChip,
    TelemonitoringRedirectButtonComponent,
  ],
  exports: [
    SequenceListComponent,
    SequencePreviewDrawerComponent,
    SequenceStacksPreviewComponent,
  ],
})
export class SequenceModule {}
