<div class="flex flex-col items-center my-5 gap-6 px-4">
  <div class="flex justify-center items-center gap-3">

    <tui-icon 
      [icon]="icon || defaultStatusInformations[state].icon"
      class="h-8 w-8"
      data-testid="icon"
      [ngClass]="defaultStatusInformations[state].color"
    ></tui-icon>
    @if (showTitle) {
      <h5
        [ngClass]="defaultStatusInformations[state].color"
      >
        {{ title || defaultStatusInformations[state].title }}
      </h5>
    }
  </div>
  <p class="text-lg max-w-prose text-tertiary text-center text-balance">
    {{ description || defaultStatusInformations[state].description }}
  </p>
  <ng-content></ng-content>

</div>
